import dogman from './images/dogman.jpg'
import outlive from './images/outlive.jpg'
import easter from './images/easter.jpg'

export const books = [
    {
        author: 'Dav Pilkey',
        title: 'Dog Man: Twenty Thousand Fleas Under the Sea: A Graphic Novel',
        img: dogman,
        id: 1,
    },
    {
        author: 'Dr. Peter Attia',
        title: 'Outlive: The Science and Art of Longevity',
        img: outlive,
        id: 2,
    },
    {
        author: 'Adam Wallace',
        title: 'How To Catch the Easter Bunny',
        img: easter,
        id: 3,
    },
]
